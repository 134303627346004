










import { defineComponent } from 'vue-demi';
import Downloader from '../components/Downloader.vue';

export default defineComponent({
  components: {
    Downloader,
  },
});
