





































import { defineComponent, ref, computed } from 'vue-demi';
import { useApi, ImportProgressEvent } from '@/api';
import RequireOnline from '@/components/RequireOnline.vue';
import { useRouter } from '@/router';
import { usePartsStore } from '../partsStore';
import { db } from '../AppDb';
import { AxiosError } from 'axios';

export default defineComponent({
  components: {
    RequireOnline,
  },
  setup() {
    const api = useApi();
    const router = useRouter();
    const importing = ref(false);
    const progress = ref<ImportProgressEvent>();
    const parts = usePartsStore();
    const updatesCount = computed(() => parts.updatesCount.value);

    async function checkUpdates() {
      return parts.getUpdates().catch((err: AxiosError) => {
        if (err.response && err.response.status === 401) {
          router.replace({
            name: 'login',
          });
        } else {
          console.error(err);
        }
      });
    }

    async function onStart() {
      importing.value = true;
      try {
        const tasks = await api.downloader.getUpdatesItems();
        await api.downloader.download(tasks, (event) => {
          progress.value = event;
        });
        await checkUpdates();
        await parts.load();
      } finally {
        importing.value = false;
      }
    }

    function goBack() {
      router.push('/');
    }

    async function resetDb() {
      await db.delete();
      window.location.reload();
    }

    async function resetSw() {
      const registrations = await navigator.serviceWorker.getRegistrations();
      await Promise.all(registrations.map((r) => r.unregister));
      window.location.reload();
    }

    checkUpdates();

    return {
      importing,
      progress,
      onStart,
      goBack,
      updatesCount,
      resetDb,
      resetSw,
    };
  },
});
